"use client";
import { use, useEffect, useState } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import UIButton from '../buttons/UIButton';
import styled from '@emotion/styled';
import UIText from '../text/UIText';
import UISelect from '../inputs/select/UISelect';
import { useModal } from 'mui-modal-provider';
import { SessionInfo, useCurrentSessionContext } from '@/context/currentSessionContext';
import { isSucursal, isRoleSucOrPromotor, isRoleSucPromotorOrAgent } from '@/utils/roles';
import { useGetAgents } from '@/hooks/useGetAgent';
import { useGetPromotedAgents } from '@/hooks/useGetPromotedAgents';
import { capitalizeFirstLetter, delay } from '@/utils/functions';
import { GetAgentsResponse } from '@/services/get-agents/getAgentsService';
import { Option } from "@/components/inputs/select/UISelectProps";
import UILoader from "../loader/UILoader";
import { useComparisionManagement } from "@/hooks/useComparisonManagement";
import { CompanyName, getValueForAserta, getValueForInsurgentes, isAserta, isInsurgentes } from "@/utils/companies";
import { enqueueSnackbar } from "notistack";
import { filter } from "jszip";

export interface ConfirmationDialogProps extends DialogProps {
  processId: string;
  companyName: CompanyName;
  onAccept: (processId: string, companyName: string) => void;
}
const LogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "48px",
  height: "48px",
  border: "1px solid #EAECF0",
  borderRadius: "10px",
});

const Logo = styled("img")({
  width: "36px",
  height: "36px",
});


const StyledDialog = styled(Dialog)(({ theme, maxWidth }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // Estilo para el backdrop para hacerlo casi blanco
  "& .MuiBackdrop-root": {
    backgroundColor: "rgba(12, 17, 29, 0.70)",
    backdropFilter: "blur(4px)",
  },
  '& .MuiPaper-root': {
    borderRadius: '10px',
  },
  '& .MuiDialog-container': {
    maxWidth: "400px",
  }
}));

const getSuc = (_company: string, sessionInfo?: SessionInfo) => sessionInfo?.company?.find((item) => item.name.toUpperCase() == _company.toUpperCase());
const invertCotejo = (value: string) => value === 'Y' ? "N" : value === 'N' ? "Y" : value;

const UIDuplicateRegistrationModal: React.FC<ConfirmationDialogProps> = ({
  processId,
  companyName,
  onAccept,
  ...props
}) => {
  const getAgentsService = useGetAgents();
  const getPromotedAgents = useGetPromotedAgents();
  const { session } = useCurrentSessionContext();
  const subsidiaries = getSuc(companyName, session);
  const optionsSubsidiaries = subsidiaries?.subsidiary.map((item) => ({ id: item.subsidiary + " - " + item.agentPositionName, value: item.subsidiary + " - " + item.agentPositionName }))
  const [subsidiary, setSubsidiary] = useState("");
  const [fullAgents, setFullAgents] = useState<GetAgentsResponse[]>([]);
  const [optionsAgents, setOptionsAgents] = useState<Option[]>([]);
  const [agent, setAgent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {  duplicateComparison } = useComparisionManagement();
  const [canNext, setCanNext] = useState(false);

  const duplicateHandler =  () => {
    setIsLoading(true);
    const filteredSubsidiary = subsidiaries?.subsidiary.find((item) => item.subsidiary + " - " + item.agentPositionName === subsidiary);
    const filteredAgent = fullAgents.find((item) => item.numAgente + " - " + item.nombreAgente === agent);
    const agentSession = session?.user?.agentName;
    const personType = filteredAgent?.tipoPersona === 'Corredor' ? "Moral" : "Física";
    debugger;
    duplicateComparison({
      idProceso: processId,
      data:{
        tipoPersona: personType,
        agente: getValueForAserta(companyName, filteredAgent?.nombreAgente ?? agentSession),
        agenteInsurgentes: getValueForInsurgentes(companyName, filteredAgent?.nombreAgente ?? agentSession),
        sucursalAgente: getValueForAserta(companyName, filteredSubsidiary?.subsidiary),
        sucursalAgenteInsurgentes: getValueForInsurgentes(companyName, filteredSubsidiary?.subsidiary),
        idAgente: session?.user?.login ?? "",
        idAgenteAserta: getValueForAserta(companyName, filteredSubsidiary?.agentId),
        idAgenteInsurgentes: getValueForInsurgentes(companyName, filteredSubsidiary?.agentId),
        cotejoManual: isAserta(companyName) ? invertCotejo(filteredSubsidiary?.firmaDigital ?? "") : "",
        cotejoManualInsurgentes: isInsurgentes(companyName) ? invertCotejo(filteredSubsidiary?.firmaDigital ?? "") : "",
        idPuestoAgente: getValueForAserta(companyName, filteredSubsidiary?.agentPosition),
        idPuestoAgenteInsurgentes: getValueForInsurgentes(companyName, filteredSubsidiary?.agentPosition),
        agenteCorredorId: getValueForAserta(companyName, session?.user?.agentId ),
        agenteCorredorIdInsurgentes: getValueForInsurgentes(companyName, session?.user?.agentId ),
      }
      
    }).then((response) => {
      debugger;
      if ('message' in response) {
        enqueueSnackbar(response.message, { variant: "error" });
      }else{        
        if(props.onClose){
          props.onClose({}, "backdropClick");
        }   
        enqueueSnackbar("Solicitud de replica se realizó con éxito", { variant: "success", preventDuplicate: true });     
      }
      
    }).catch((error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
      });
    }).finally(() => {
      setIsLoading(false);
    });
  }
  async function processAgents(agents: GetAgentsResponse[]) {
    setFullAgents(agents);
    const sortedAgents = agents.sort((a, b) => {
      if (a.nombreAgente < b.nombreAgente) {
        return -1;
      }
      if (a.nombreAgente > b.nombreAgente) {
        return 1;
      }
      return 0;
    });
    const agentsOption = sortedAgents.map((agent: { numAgente: any; nombreAgente: any; }) => {
      return { id: agent.numAgente, value: agent.numAgente + " - " + agent.nombreAgente };
    });
    setOptionsAgents(agentsOption);
    if(agentsOption.length == 1){
      setAgent(agentsOption[0].value);
    }else{
      const filteredSubsidiary = subsidiaries?.subsidiary.find((item) => item.subsidiary + " - " + item.agentPositionName === subsidiary);
      setAgent(filteredSubsidiary?.agentNumber + " - " + session?.user?.agentName);
    }
    await delay(1000);
  }

  useEffect(() => {
    if (optionsSubsidiaries && optionsSubsidiaries.length > 0) {
      setSubsidiary(optionsSubsidiaries[0].value);
    }
  }, [optionsSubsidiaries]);


  useEffect(() => {
    if (isRoleSucPromotorOrAgent(session) && subsidiary) {
      setCanNext(false);
      const filteredSubsidiary = subsidiaries?.subsidiary.find((item) => item.subsidiary + " - " + item.agentPositionName === subsidiary);
      if (isSucursal(session?.user?.role.toUpperCase())) {
        setIsLoading(true);
        getAgentsService({ idSucursal: filteredSubsidiary?.subsidiaryId ?? "", empresa: capitalizeFirstLetter(companyName) })
          .then(async (response) => {
            await processAgents(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            setIsLoading(false);
            if(isSucursal(session?.user?.role.toUpperCase())){ {
              setAgent("");
              setCanNext(false);
            }
            }
          });
      } else {
        setIsLoading(true);
        getPromotedAgents({ idPromotor: filteredSubsidiary?.corredorId ?? "", empresa: capitalizeFirstLetter(companyName) })
          .then(async (response) => {
            await processAgents(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
            setIsLoading(false);
          });
      }
    }else{
      setCanNext(true);
    }
  }, [subsidiary]);

  useEffect(() => {
    if(agent){
      setCanNext(true);
    }
  }, [agent]);
  return (
    <>
      <StyledDialog disableEnforceFocus {...props} disableEscapeKeyDown onClose={() => { }} sx={{ padding: "40px", borderRadius: "16px", }}>
        <DialogTitle sx={{ padding: "15px", marginX: "10px", marginTop: "10px" }}>
          <LogoContainer> <Logo src="/assets/icons/face.svg" alt="Logo" /></LogoContainer>
          <UIText color="blue" size="text-xl" text="Replicar información" />
        </DialogTitle>
        <DialogContent sx={{ padding: "15px", marginX: "10px" }}>
          <DialogContentText>
            <UIText text={`¿Estás seguro de replicar esta solicitud para la aseguradora ${companyName}? Una vez replicada, cualquier modificación deberá hacerse por separado en cada solicitud.`} size="text-md" weight="light" color="blueGray" /> </DialogContentText>
          <UISelect id="subsidiarySelect" label="Sucursal" size="small" options={optionsSubsidiaries} value={subsidiary} onChange={(event) => setSubsidiary(event)} />
          {
            optionsAgents.length > 0 &&
            <UISelect id="agentsSelect" label="Agente" size="small" options={optionsAgents} value={agent} onChange={(event) => setAgent(event)} />
          }
        </DialogContent>
        <DialogActions sx={{ marginX: "15px", marginBottom: "10px" }}>
          <UIButton label="Cancelar" onClick={() => props.onClose!({}, "backdropClick")} color="secondary" fullWidth />
          <UIButton label="Si, replicar" onClick={duplicateHandler} color="orange" fullWidth disabled={!canNext} />
        </DialogActions>
      </StyledDialog>
      <UILoader open={isLoading} loaderText={message} />
    </>
  );
}

export default UIDuplicateRegistrationModal;