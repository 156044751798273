import React, { FunctionComponent, useMemo, memo } from "react";
import BreadcrumbsProps, { IBreadcrumb } from "./BreadcrumbsProps";
import UIText from "../text/UIText";
import Icon from "../icon/icon";
import { TColors } from "../text/UITextProps";

const baseBreadcrumbsStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
} as const;

const styles = {
  container: {
    ...baseBreadcrumbsStyles,
    gap: "6px",
  },
  item: {
    ...baseBreadcrumbsStyles,
    gap: "4px",
  }
} as const;

interface BreadcrumbItemProps {
  option: IBreadcrumb;
  isLast: boolean;
  color: TColors;
}

const BreadcrumbItem = memo(({ option, isLast, color }: BreadcrumbItemProps) => {
  const { label, link, icon, onClick } = option;
  
  const itemStyle = useMemo(() => ({
    ...styles.item,
    cursor: (onClick || link) ? 'pointer' : 'default'
  }), [onClick, link]);

  return (
    <>
      <div style={itemStyle} onClick={onClick}>
        {icon}
        {label && (
          <UIText 
            text={label} 
            link={link} 
            color={isLast ? "gray600" : color} 
            weight={isLast ? 'medium' : 'semibold'} 
          />
        )}
      </div>
      {!isLast && <Icon name="KeyboardArrowRight" />}
    </>
  );
});

BreadcrumbItem.displayName = 'BreadcrumbItem';

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ 
  options = [], 
  color = 'red4' 
}) => {
  const memoizedOptions = useMemo(() => options, [options]);

  if (!memoizedOptions.length) return null;

  return (
    <div style={styles.container}>
      {memoizedOptions.map((option, index) => (
        <BreadcrumbItem
          key={option.label || index}
          option={option}
          isLast={index === memoizedOptions.length - 1}
          color={color}
        />
      ))}
    </div>
  );
};

export default memo(Breadcrumbs);