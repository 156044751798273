import UIButton from "@/components/buttons/UIButton";
import UIText from "@/components/text/UIText";
import { Box, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";

interface IUIContentModalProps {
  type: 'view' | 'duplicate' | 'cancel';
  idSolicitud: string;
  isFad?: boolean;
  onCanceled: (id: string) => Promise<void>;
  onDuplicated?: (id: string) => Promise<void>;
  pushed: (url: string) => void;
  close: () => void;
}

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: "16px",
  justifyContent: 'center',
  width: '100%',
});

const UIContentModal: FC<IUIContentModalProps> = ({ type, idSolicitud, onCanceled, onDuplicated, pushed, close, isFad = false }) => {
  const modalContentByTpe = {
    view: {
      title: "Documentación",
      description: "Puedes revisar la documentación contractual para corregirla o enviar la firma digital. Si prefieres firmar manualmente, también puedes subir el contrato físico.",
      buttons: [
        { label: "Ver documentación", color: "orange", onClick: () => pushed(`/sing/cosignal?idProcess=${idSolicitud}`) },
        { label: "Subir contrato físico", color: "secondary", onClick: () => pushed(`/sing/load-signature?idProcess=${idSolicitud}`) },
      ],
    },
    duplicate: {
      title: "¿Deseas duplicar el alta en proceso?",
      description: "Estás a punto de duplicar un alta en proceso. Una vez duplicado, podrás modificar la información y/o enviar la firma digital.",
      buttons: [
        { label: "Cancelar", color: "secondary", onClick: close },
        { label: "Duplicar alta", color: "orange", onClick: () => onDuplicated?.(idSolicitud) },
      ],
    },
    cancel: {
      title: "¿Deseas eliminar el alta en proceso?",
      description: "Estás a punto de eliminar un alta en proceso. Una vez eliminado no podrás recuperar la información.",
      buttons: [
        { label: "Cancelar", color: "secondary", onClick: close },
        { label: "Eliminar", color: "orange", onClick: () => onCanceled(idSolicitud) },
      ],
    },
  };

  const [modalContent, setModalContent] = useState(modalContentByTpe[type]);

  useEffect(() => {
    setModalContent(modalContentByTpe[type]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const getLabelAction = (type: string) => {
    switch (type) {
      case 'view':
        return 'Subir contrato físico';
      case 'duplicate':
        return 'Duplicar alta';
      case 'cancel':
        return 'Eliminar';
      default:
        return '';
    }
  };

  const clickButton = (index: number) => {
    return modalContent.buttons[index];
  };

  return (
    <StyledBox>
      <UIText text={modalContent.title} size="text-xl" weight="bold" color="primary" />
      <UIText text={modalContent.description} weight="light" color="primary" sx={{ width: '90%' }} />
      <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row', width: '100%' }}>
        <UIButton
          label={type === 'view' ? 'Ver documentación' : 'Cancelar'}
          color={type === 'view' ? 'orange' : 'secondary'}
          onClick={() => { clickButton(0).onClick() }}
          fullWidth
        />
        { ((!isFad && type === 'view') || type !== 'view') &&
          <UIButton
            label={getLabelAction(type)}
            color={type === 'view' ? 'secondary' : 'orange'}
            onClick={() => { clickButton(1).onClick() }}
            fullWidth
          />}
      </Box>
    </StyledBox>
  );
};

export default UIContentModal;
